@font-face {
    font-family: "AmiriWeb";
    src: url("~fonts/Amiri-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "AmiriWeb";
    src: url("~fonts/Amiri-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "AmiriWeb";
    src: url("~fonts/Amiri-Slanted.woff") format("woff");
    font-weight: normal;
    font-style: italic;
}
